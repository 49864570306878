<template>
  <div class="pipedriveContainer">
    <div class="pipedriveWebForms pipedrive" data-pd-webforms="https://webforms.pipedrive.com/f/1AgMdqKZxNZ20MBgVxvAPNC1EsrFXxp1bNby7cgmmXab8ObSBnGJiMIhMxdq7zObF"></div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Получение демо-доступа к системе DATAFORCE',
      meta: [
        {
          name: 'description',
          content: 'Заполните форму и получите демо-доступ к аналитической платформе DATAFORCE'
        }
      ]
    }
  },
  mounted () {
    const script = document.createElement('script')
    script.src = "https://webforms.pipedrive.com/f/loader"
    document.body.appendChild(script)
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/mixins';
.pipedriveContainer {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  margin: 0 auto;
  background-color: #f7f7f7;

  .pipedrive {
    max-width: 480px;
    margin: 0px auto;
    padding: 32px;
    color: #1f1f1f;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 4px;
    transition: all 400ms ease 0s;
  }
}

@include phones {
  .pipedrive {
    padding: 16px 12px;
  }
}
</style>
